/* ########## COMPONENT IMPORTS ########## */
import HomePageComponent from '../components/Pages/HomePageComponent';
import SEOHeaderComponent from '@/components/Pages/SEOHeaderComponent';
import React, { useEffect, useState } from 'react';
import {
  persistClientIp,
  persistUserCitiesFromIp,
} from '../components/util/index';
import { getPageProps } from '../components/util/hbutils';
import { getMetaDataApi, getPageParams } from '../routing';
import { wrapper } from '@/redux/store';
import {
  geBlogPostProjects,
  getCities,
  // geBlogPostProjects,
  getDevelopers,
  getDiscountDealProjects,
  getGlobalSearchData,
  getLivabilityProjects,
  getRecommendedProjects,
  getTestimonials,
  setCurrentPageType,
} from '@/redux/actions/main';
import useMaterialMediaQuery from '@/components/packages/use-material-media-query';

const Root = props => {
  const {
    city,
    onCitySelect,
    locations,
    module_type,
    customProps,
    originalUrl,
    isMobileView,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    wishlistPropertyDeletedCall,
    callWishlistApi,
    cityObj,
  } = props;
  // console.log('isMobileView', props)
  persistClientIp(props);
  persistUserCitiesFromIp(props.userCities);

  const [moduletype, setModuleType] = useState(module_type);

  const [small] = useMaterialMediaQuery();

  const onModuleSelect = (id, name) => {
    setModuleType(name);
  };

  useEffect(() => {
    setCurrentPageType('HomePage');
  }, []);

  return (
    <>
      <SEOHeaderComponent
        meta_data={customProps}
        full_url_slug={originalUrl}
        pageName="Home_Page"
      />
      <HomePageComponent
        city={city ?? cityObj}
        locations={locations}
        onModuleSelect={onModuleSelect}
        module_type={moduletype}
        clientIp={props.clientIp || ''}
        onCitySelect={onCitySelect}
        isMobileView={isMobileView || small}
        wishlistpropertydeletedtrigger={wishlistpropertydeletedtrigger}
        addwishlisttrigger={addwishlisttrigger}
        wishlistPropertyDeletedCall={wishlistPropertyDeletedCall}
        callWishlistApi={callWishlistApi}
        discountDealData={props.discountDealData}
        dowData={props.livabilityData}
        recommendProjectData={props.recommendProjectData}
        developerData={{ developers: props.developersData }}
      />
    </>
  );
};

export default Root;

export const getServerSideProps = wrapper.getServerSideProps(
  store =>
    async ({ res, req }) => {
      let cityId, cityName;
      let module_type = 'buy';
      const pageName = 'HomePage';
      let params, data;
      if (req.url.startsWith('/_next/data')) {
        data = await Promise.resolve(getMetaDataApi(['', '']));
        cityId = 1;
        module_type = 'buy';
        cityName = 'mumbai';
      } else {
        // let reqUrl = req.url.split('/');
        // if (reqUrl.length == 3) {
        //   module_type = `${reqUrl[1] ?? 'buy'}`;
        // }
        // city_id_mapping.forEach(item => {
        //   if (item.name.toLowerCase() == cityName) {
        //     cityId = item.id;
        //   }
        params = getPageParams(req.url);
        if (
          params[0].toLowerCase() !== 'buy' &&
          params[0].toLocaleLowerCase() !== 'rent'
        ) {
          params[1] = params[0];
          params[0] = '';
        }
        data = await Promise.resolve(getMetaDataApi(params));
        if (data.status_code === 301 && data.rectified_url) {
          res.writeHead(301, { location: `/${data.rectified_url}` });
          res.end();
          return;
        }
        cityName = data.data.cityName;
        cityId = data.data.cityId;
        module_type =
          data?.data?.popularName !== '' ? data?.data?.popularName : 'buy';

        // *** applying redux toolkit wrapper for dispetch the actions ***//
        await Promise.resolve(store.dispatch(setCurrentPageType(pageName)));
        await Promise.resolve(
          store.dispatch(
            getDiscountDealProjects({
              city_id: cityId ?? 1,
              limit: 3,
              city: cityName ?? 'mumbai',
              module_type: 'buy',
              section: 'investment',
              skip: 0,
              user_id: 13,
            })
          )
        );
        await Promise.resolve(
          store.dispatch(
            getLivabilityProjects({
              city_id: cityId ?? 1,
              limit: 6,
              city: cityName ?? 'mumbai',
              module_type: 'buy',
              section: 'dealoftheweek',
              skip: 0,
              user_id: 0,
            })
          )
        );
        await Promise.resolve(
          store.dispatch(
            getRecommendedProjects({
              city_id: cityId ?? 1,
              limit: 6,
              city: cityName ?? 'mumbai',
              module_type: 'buy',
              section: 'recommend',
              skip: 0,
              user_id: 0,
            })
          )
        );
        await Promise.resolve(store.dispatch(getCities()));
        await Promise.resolve(
          store.dispatch(
            getDevelopers({
              skip: 0,
              limit: 6,
              location: cityName ?? 'mumbai',
            })
          )
        );
        await Promise.resolve(
          store.dispatch(
            getGlobalSearchData({
              propertytype: module_type ?? 'buy',
              selectedCity: cityId,
              keyword: '',
            })
          )
        );
        await Promise.resolve(
          store.dispatch(
            geBlogPostProjects({
              city: cityName ?? 'mumbai',
              module: module_type ?? 'buy',
            })
          )
        );
        await Promise.resolve(
          store.dispatch(
            getTestimonials({
              skip: 0,
              limit: 20,
            })
          )
        );
      }
      if (!data.success) {
        res.statusCode = 404;
        return {
          notFound: true,
        };
      }
      // ?******* Providing data :   ********//
      let reducer = store.getState();
      let discountDealData = reducer.investmentReducer.error;
      reducer.investmentReducer.error = JSON.stringify(discountDealData);
      reducer.BlogPostReducer.error = JSON.stringify(discountDealData);
      let pageProps = await Promise.resolve(
        getPageProps(req, 'homepage', data)
      );
      return {
        props: {
          ...pageProps,
          pageName,
          params,
          cityObj: { id: cityId, name: cityName },
          // reducerData : reducer,
        },
      };
    }
);
